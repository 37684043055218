import { PageContainer } from '@flock/shared-ui'
import React from 'react'

type OfferPageWrapperProps = {
  leadUuid: string
  children: React.ReactNode
}

const OfferPageWrapper = (props: OfferPageWrapperProps) => {
  const { children, leadUuid } = props

  return (
    <PageContainer
      title="Overmoon | Offer Page"
      trackingName="offer-page"
      trackingProperties={{
        leadUuid,
      }}
    >
      {children}
    </PageContainer>
  )
}

export default OfferPageWrapper
