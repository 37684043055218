import { TrackedButton } from '@flock/shared-ui'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { navigate } from 'gatsby'
import PrelimOfferSection from '../PrelimOfferSection'
import { ONBOARDING_PATH } from '../../../../routeConstants'
import OfferDocuments from '../../OfferDocuments/OfferDocuments'

const GenericPrelimImportantResourcesTablet = (
  props: GenericPrelimImportantResourcesProps
) => {
  const { slackThreadUrl, staticDocumentOverrides, leadDocuments } = props
  return (
    <PrelimOfferSection sectionId="important-resources">
      <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
        <Grid item justifyContent="center" xs={12} sx={{ paddingTop: '32px' }}>
          <Typography
            variant="h2"
            alignSelf="flex-start"
            color="moneyGreen.main"
          >
            Important resources.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '32px' }}>
          <OfferDocuments
            leadDocuments={leadDocuments || []}
            staticDocumentOverrides={staticDocumentOverrides || {}}
            slackThreadUrl={slackThreadUrl}
            isGenericOfferPage
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            gap="24px"
            pt="24px"
            alignItems="center"
          >
            <Typography
              variant="h3"
              color="moneyGreen.main"
              sx={{ textAlign: 'center' }}
            >
              Discover more about whether Flock is the right fit for you.{' '}
            </Typography>
            <TrackedButton
              size="small"
              onClick={() => navigate(ONBOARDING_PATH)}
              trackingConfig={{ name: 'genericOfferPageOnboarding' }}
              sx={{ height: '64px' }}
            >
              Submit a property for valuation
            </TrackedButton>
          </Box>
        </Grid>
      </Box>
    </PrelimOfferSection>
  )
}

export default GenericPrelimImportantResourcesTablet
