import { TrackedButton } from '@flock/shared-ui'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { navigate } from 'gatsby'
import PrelimOfferSection from '../PrelimOfferSection'
import OfferDocuments from '../../OfferDocuments/OfferDocuments'
import { ONBOARDING_PATH } from '../../../../routeConstants'

const GenericPrelimImportantResourcesDesktop = (
  props: GenericPrelimImportantResourcesProps
) => {
  const { slackThreadUrl, staticDocumentOverrides, leadDocuments } = props
  return (
    <PrelimOfferSection sectionId="important-resources">
      <Box
        display="flex"
        flexDirection="row"
        sx={{ width: '100%', paddingTop: '32px' }}
      >
        <Grid item justifyContent="center" xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            gap="32px"
            sx={{ width: '372px' }}
            pt="24px"
          >
            <Typography
              variant="h2"
              alignSelf="flex-start"
              color="moneyGreen.main"
            >
              Important resources.
            </Typography>
            <Typography
              variant="h4"
              alignSelf="flex-start"
              color="moneyGreen.main"
            >
              Discover more about whether Flock is the right fit for you.{' '}
            </Typography>
            <TrackedButton
              size="small"
              onClick={() => navigate(ONBOARDING_PATH)}
              sx={{ height: '64px' }}
              trackingConfig={{ name: 'genericOfferPageOnboarding' }}
            >
              <Typography variant="p1">
                Submit a property for valuation
              </Typography>
            </TrackedButton>
          </Box>
        </Grid>

        <Grid item sx={{ paddingTop: '32px' }}>
          <OfferDocuments
            leadDocuments={leadDocuments || []}
            staticDocumentOverrides={staticDocumentOverrides || {}}
            slackThreadUrl={slackThreadUrl}
            isGenericOfferPage
          />
        </Grid>
      </Box>
    </PrelimOfferSection>
  )
}

export default GenericPrelimImportantResourcesDesktop
