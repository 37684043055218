import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  BathroomIcon,
  BedroomIcon,
  HouseOutlinedIcon,
  SqftIcon,
} from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { numberWithCommas } from '../../utils'
import HomeDetail from '../HomeDetail'
import PortfolioHomeDetails from '../PortfolioHomeDetails'
import { OfferPortfolioPresentationalProps } from './offerPortfolioTypes'

const OfferPortfolioMobile = (props: OfferPortfolioPresentationalProps) => {
  const {
    streetViewUrl,
    street,
    bedrooms,
    bathrooms,
    sqft,
    homeType,
    transactionType,
    leadName,
    portfolioName,
    portfolioDoorCount,
    portfolioAssetCount,
    portfolioCityState,
    portfolioSFRDoors,
    portfolioMultifamilyDoors,
    capex,
    offerPrice,
  } = props

  const HomeDetailComponents = (
    <Box display="flex" flexDirection="column" gap="6px">
      <Box display="flex" flexDirection="column" gap="8px">
        {bedrooms ? (
          <HomeDetail icon={BedroomIcon} text={`${bedrooms} bed`} />
        ) : null}
        {bathrooms ? (
          <HomeDetail icon={BathroomIcon} text={`${bathrooms} bath`} />
        ) : null}
      </Box>
      {sqft ? (
        <HomeDetail icon={SqftIcon} text={`${numberWithCommas(sqft)} sq ft`} />
      ) : null}
      {homeType && <HomeDetail icon={HouseOutlinedIcon} text={homeType} />}
    </Box>
  )

  const TwoColumnHomeDetailComponents = (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      justifyContent="space-between"
    >
      <Box width="100%">
        <Box display="flex" flexDirection="column" gap="8px">
          <Box display="flex" flexDirection="row" gap="8px">
            {bedrooms ? (
              <HomeDetail icon={BedroomIcon} text={`${bedrooms} bed`} />
            ) : null}
            {bathrooms ? (
              <HomeDetail icon={BathroomIcon} text={`${bathrooms} bath`} />
            ) : null}
          </Box>
          {sqft ? (
            <HomeDetail
              icon={SqftIcon}
              text={`${numberWithCommas(sqft)} sq ft`}
            />
          ) : null}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box width="100%">
          {homeType && <HomeDetail icon={HouseOutlinedIcon} text={homeType} />}
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      {transactionType === Core_TransactionType.TransactionTypePortfolio_721 ? (
        <PortfolioHomeDetails
          leadName={leadName}
          portfolioName={portfolioName}
          portfolioDoorCount={portfolioDoorCount}
          portfolioAssetCount={portfolioAssetCount}
          portfolioCityState={portfolioCityState}
          portfolioSFRDoors={portfolioSFRDoors}
          portfolioMultifamilyDoors={portfolioMultifamilyDoors}
          capex={capex}
          offerPrice={offerPrice}
        />
      ) : (
        <Box display="flex" flexDirection="row">
          {streetViewUrl ? (
            <>
              <Box
                component="img"
                src={streetViewUrl}
                alt={`Picture of ${street}`}
                width="50%"
                height="136px"
                sx={{
                  boxShadow: 8,
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                }}
              />

              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                padding="16px"
                sx={{
                  boxShadow: 8,
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  backgroundColor: 'lavender3.main',
                }}
              >
                <>{HomeDetailComponents}</>
              </Box>
            </>
          ) : (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              padding="16px"
              sx={{
                boxShadow: 8,
                borderRadius: '8px',
                backgroundColor: 'lavender3.main',
              }}
            >
              <Typography
                sx={{ paddingBottom: '16px', textAlign: 'center' }}
                variant="cta"
              >
                {street}
              </Typography>

              <>{TwoColumnHomeDetailComponents}</>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

export default OfferPortfolioMobile
