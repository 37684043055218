import { TrackedButton } from '@flock/shared-ui'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { useCalendly } from '../utils'
import ScheduleCall from '../SharedComponents/ScheduleCall'
import OfferPagePaper from './OfferPagePaper'
import OfferPageContext from './OfferPageContext'
import { LeadStatus } from './offerPageTypes'

type FloatingMessageProps = {
  leadStatus?: LeadStatus
  backgroundColor?: string
}

const FloatingMessage = (props: FloatingMessageProps) => {
  const { leadStatus, backgroundColor } = props
  const context = useContext(OfferPageContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'floating-cta-offer-page',
    scheduleUrl: context.calendlyLink || '',
  })

  const buttonText = `Schedule a call`
  let text = `Questions about your offer? We're happy to help.`

  if (
    [
      LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED,
      LeadStatus.SCHEDULE_AN_INSPECTION_BOOKED,
      LeadStatus.SCHEDULE_AN_INSPECTION_POST_INSPECTION,
      LeadStatus.REVIEW_FINAL_OFFER,
      LeadStatus.SIGN_CONTRIBUTION_AGREEMENT,
      LeadStatus.CLOSING_PROCESSES,
    ].includes(leadStatus!)
  ) {
    text = 'Questions about next steps? We’re happy to help.'
  } else if ([LeadStatus.DISCUSS_YOUR_OFFER].includes(leadStatus!)) {
    text = `Questions about your offer or Overmoon? We're happy to help.`
  }

  return (
    <OfferPagePaper
      backgroundColor={backgroundColor || 'rgba(255, 255, 255, 0.5)'}
    >
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography
          variant={isMobile ? 'p2' : 'cta'}
          fontSize="1rem"
          lineHeight="1.5rem"
        >
          {text}
        </Typography>
        <ScheduleCall {...otherCalendlyProps} user={context.user} />
        <TrackedButton size="small" variant="tertiary" onClick={onOpenCalendly}>
          <Typography variant="c1" fontSize="18px">
            {buttonText}
          </Typography>
        </TrackedButton>
      </Box>
    </OfferPagePaper>
  )
}

export default FloatingMessage

FloatingMessage.defaultProps = {
  leadStatus: LeadStatus.DISCUSS_YOUR_OFFER,
  backgroundColor: '',
}
