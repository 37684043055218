import {
  Core_LeadDocument,
  Core_LeadStage,
  Core_ValuationType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { StaticDocumentOverrides } from './OfferDocuments/offerDocumentsTypes'
import { ShareCalculationDrawerProps } from './ShareCalculationDrawer'

// eslint-disable-next-line import/prefer-default-export
export const enum LeadStatus {
  GET_AN_OFFER = 'get-an-offer',
  DISCUSS_YOUR_OFFER = 'discuss-your-offer',
  SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED = 'schedule-an-inspection-pre-inspection-booked',
  SCHEDULE_AN_INSPECTION_BOOKED = 'schedule-an-inspection-booked',
  SCHEDULE_AN_INSPECTION_POST_INSPECTION = 'schedule-an-inspection-post-inspection',
  REVIEW_FINAL_OFFER = 'review-final-offer',
  SIGN_CONTRIBUTION_AGREEMENT = 'sign-contribution-agreement',
  CLOSING_PROCESSES = 'closing-processes',
}

export type OfferPageData = {
  fullName: string
  email: string
  phoneNumber: string
  street: string
  city: string
  state: string
  zipcode: string
  cityStateZipcode: string
  hasCityStateZipcodeOverride: boolean
  latitude: number
  longitude: number
  bedrooms: number
  bathrooms: number
  halfBaths: number
  propertyCondition: string
  sqft: number
  rent: number
  mortgage: number
  scheduleInspectionRequested: boolean
  contributionAgreementRequested: boolean
  propertyTypeDisplayString: string
  hideValuation: boolean
  isPreliminary: boolean
  finalOffer: number
  prelimOffer: number
  offerLow: number
  offerHigh: number
  expiryDate: Date
  portfolioDrawerContent: ShareCalculationDrawerProps
  transactionType: string
  shareCount: number
  equityOverride: number
  shouldOverrideClosingCosts: boolean
  closingCostsOverride: number
  slackThreadUrl: string
  portfolioName: string
  portfolioDoorCount: number
  portfolioAssetCount: number
  portfolioCityState: string
  portfolioSFRDoors: number
  portfolioMultifamilyDoors: number
  cashTakeout: number
  capexCosts: number
  updateMortgage: (amount: number) => void
  updateContributionAgreementRequested: (
    contributionAgreementRequested: boolean
  ) => void
  updateScheduleInspectionRequested: (
    contributionAgreementRequested: boolean
  ) => void
  leadStage: Core_LeadStage
  shouldOverrideLeadStatus: boolean
  overriddenRange: boolean
  leadStatus: LeadStatus
  leadStatusOverride: LeadStatus
  isOverriddenAddress: boolean
  saamValuationType: Core_ValuationType
  inspectionDate: Date
  staticDocumentOverrides: StaticDocumentOverrides
  leadDocuments: Core_LeadDocument[]
  leadNameOverride?: string
  offerPageContext: {
    calendlyLink?: string
    salesAssigneeName?: string
    user: {
      fullName: string
      email: string
      phoneNumber: string
      slackThreadUrl?: string
    }
  }
}
