import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import { InfoIcon } from '@flock/shared-ui'
import { formatIntegerDollars, OM_DEFAULT_PRICE_PER_SHARE } from '@flock/utils'
import { Box, Paper, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { OfferExchangePresentationalProps } from './offerExchangeTypes'

const OfferExchangeMobile = (props: OfferExchangePresentationalProps) => {
  const {
    street,
    finalOffer,
    mortgage,
    transactionType,
    cashTakeout,
    rentReduction,
    daysInRemodelDeduction,
    brokerCommission,
    onboardingFee,
    displayedClosingCosts,
    displayedOnboardingFee,
    closingCosts,
    equityValue,
  } = props

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box display="flex" flexDirection="row" gap="12px" alignItems="stretch">
        <Paper
          sx={{
            padding: '12px',
            backgroundColor: 'lavender3.main',
            borderRadius: '8px',
            width: '50%',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="8px"
            alignItems="baseline"
          >
            <Typography variant="h4" fontWeight="500">
              ${finalOffer?.toLocaleString()}
            </Typography>
            <Typography variant="p3">
              offer price for{' '}
              {transactionType ===
              Core_TransactionType.TransactionTypePortfolio_721
                ? 'your portfolio'
                : street}
            </Typography>
          </Box>
        </Paper>
        <Paper
          sx={{
            padding: '12px',
            backgroundColor: 'lavender3.main',
            borderRadius: '8px',
            width: '50%',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="8px"
            alignItems="baseline"
          >
            <Typography variant="h4" fontWeight="500">
              {Math.round(
                equityValue / OM_DEFAULT_PRICE_PER_SHARE
              ).toLocaleString()}
            </Typography>
            <Typography variant="p3">
              shares in the Overmoon 721 Fund
            </Typography>
          </Box>
        </Paper>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Paper
          sx={{
            backgroundColor: 'gray1.main',
            borderRadius: '8px',
            width: '100%',
            padding: '16px',
            display: 'flex',
            height: '272px',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="p3">Offer Price</Typography>
              <Typography variant="p3">
                ${finalOffer?.toLocaleString()}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="2px"
              >
                <Typography variant="p3">Mortgage Balance</Typography>
                <Tooltip
                  title="Overmoon will pay off any existing mortgage at closing."
                  arrow
                  placement="bottom"
                  enterTouchDelay={0}
                >
                  <Box
                    sx={{
                      display: 'inline',
                    }}
                  >
                    <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                  </Box>
                </Tooltip>
              </Box>
              <Typography variant="p3">
                -${Math.round(mortgage).toLocaleString()}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="2px"
              >
                <Typography variant="p3">
                  Onboarding (
                  {((onboardingFee - brokerCommission) * 100).toFixed(2)}
                  %)
                </Typography>
              </Box>
              <Typography variant="p3">-${displayedOnboardingFee}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="2px"
              >
                <Typography variant="p3">
                  Closing Costs ({(closingCosts * 100).toFixed(2)}
                  %)
                </Typography>
                <Tooltip
                  title="These costs may vary and will be finalized by the title company."
                  arrow
                  placement="bottom"
                  enterTouchDelay={0}
                >
                  <Box
                    sx={{
                      display: 'inline',
                    }}
                  >
                    <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                  </Box>
                </Tooltip>
              </Box>
              <Typography variant="p3">-${displayedClosingCosts}</Typography>
            </Box>
            {cashTakeout ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="p3">Cash Takeout</Typography>
                <Typography variant="p3">
                  -${Math.round(cashTakeout).toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            {rentReduction ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="p3">Rent Reduction</Typography>
                <Typography variant="p3">
                  -${Math.round(rentReduction).toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            {daysInRemodelDeduction ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="p3">Remodel Deduction</Typography>
                <Typography variant="p3">
                  -${Math.round(daysInRemodelDeduction).toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            {brokerCommission ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="p3">Broker Commission</Typography>
                <Typography variant="p3">
                  -${Math.round(brokerCommission).toLocaleString()}
                </Typography>
              </Box>
            ) : null}
            <Box
              display="flex"
              alignSelf="center"
              sx={{ width: '100%', borderTop: '1px solid grey' }}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="p3" fontWeight="600" color="moneyGreen.main">
                Equity Value in Fund
              </Typography>
              <Typography variant="p3" fontWeight="600" color="moneyGreen.main">
                ${equityValue.toLocaleString()}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="p3">Price per Share</Typography>
              <Typography variant="p3">
                / {formatIntegerDollars(OM_DEFAULT_PRICE_PER_SHARE)}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignSelf="center"
              sx={{ width: '100%', borderTop: '1px solid grey' }}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="p3" fontWeight="600" color="moneyGreen.main">
                Shares
              </Typography>
              <Typography variant="p3" fontWeight="600" color="moneyGreen.main">
                {Math.round(
                  equityValue / OM_DEFAULT_PRICE_PER_SHARE
                ).toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default OfferExchangeMobile
