import { NavLink, scrollIntoView } from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { HOMEPAGE_URL } from '../../../constants'
import OmNavbar from '../../SharedComponents/OmNavbar/OmNavbar'
import { OfferPageSidebarProps } from './offerPageSidebarTypes'

const OfferPageSidebarMobile = (props: OfferPageSidebarProps) => {
  const { sidebarItems } = props

  return (
    <OmNavbar
      offerPage
      navItems={[]}
      actions={[]}
      logoPath={HOMEPAGE_URL}
      showBackToHome
      mobileNavItemsFunction={(setMobileNavOpen: (open: boolean) => void) =>
        sidebarItems.map(
          (item: { title: any; unselectedIcon: any; id: any; hide: any }) => {
            const { title, unselectedIcon: UnselectedIcon, id, hide } = item
            const onClick = () => {
              setTimeout(() => {
                scrollIntoView(id)
              }, 200)
              setMobileNavOpen(false)
            }
            return (
              <>
                {!hide && (
                  <NavLink onClick={onClick}>
                    <Box
                      display="flex"
                      alignContent="flex-start"
                      pl="0px"
                      gap="16px"
                    >
                      <Box>
                        <UnselectedIcon width="32px" height="32px" />
                      </Box>
                      <Typography
                        variant="h3"
                        textAlign="left"
                        sx={{
                          fontSize: '24px',
                          lineHeight: '32px',
                          fontWeight: 500,
                        }}
                      >
                        {title}
                      </Typography>
                    </Box>
                  </NavLink>
                )}
              </>
            )
          }
        )
      }
    />
  )
}

export default OfferPageSidebarMobile
