import {
  OM_DEFAULT_CLOSING_AND_LEGAL_FEE,
} from '@flock/utils'
import { useMediaQuery, useTheme } from '@mui/material'
import { OfferExchangeProps } from './offerExchangeTypes'

const useOfferExchange = (props: OfferExchangeProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const {
    finalOffer,
    mortgage,
    daysInRemodelDeduction,
    onboardingFee,
    brokerCommission,
    shouldOverrideClosingCosts,
    closingCostsOverride,
    rentReduction,
    cashTakeout,
  } = props

  let closingCosts = OM_DEFAULT_CLOSING_AND_LEGAL_FEE
  if (shouldOverrideClosingCosts) {
    closingCosts = closingCostsOverride
  }

  // This is valuation, less all fees and reductions
  const feeModifier = 1 - onboardingFee
  const equityValue = Math.round(
    Math.round(finalOffer * feeModifier) -
      Math.round(finalOffer * closingCosts) -
      Math.round(mortgage) -
      Math.round(rentReduction) -
      Math.round(daysInRemodelDeduction) -
      Math.round(cashTakeout)
  )

  const displayedClosingCosts = Math.round(
    closingCosts * finalOffer
  ).toLocaleString()
  const displayedOnboardingFee = Math.round(
    finalOffer * (onboardingFee - brokerCommission)
  ).toLocaleString()

  return {
    ...props,
    equityValue,
    closingCosts,

    displayedClosingCosts,
    displayedOnboardingFee,

    isMobile,
    isTablet,
  }
}

export default useOfferExchange
