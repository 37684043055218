import { Om721Logo, SidebarWithIcons } from '@flock/shared-ui'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { navigate } from 'gatsby'
import React from 'react'
import { HOMEPAGE_URL } from '../../../constants'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { OfferPageSidebarProps } from './offerPageSidebarTypes'

const OfferPageSidebarTablet = (props: OfferPageSidebarProps) => {
  const {
    sidebarRef,
    sidebarHeight,
    sidebarItems,
    onChangeSidebar,
    selectedSection,
  } = props

  const theme = useTheme()

  return (
    <>
      <Box width="100%" position="relative" zIndex={6} pt="24px">
        <SectionLayout name="sidebar" verticalPadding="0px" columns={7}>
          <Grid item>
            <Box onClick={() => navigate(HOMEPAGE_URL)}>
              <Typography variant="h4" fontWeight={500}>
                <Om721Logo color="midnightBlue" />
              </Typography>
            </Box>
          </Grid>
        </SectionLayout>
      </Box>

      <Box
        position="sticky"
        top="40px"
        width="100%"
        sx={{ pointerEvents: 'none' }}
        zIndex={5}
      >
        <Box ref={sidebarRef} marginBottom={`${sidebarHeight * -1}px`}>
          <SectionLayout
            name="sidebar"
            sidePadding="0px"
            verticalPadding="28px"
            columns={7}
            backgroundColor="#ffffff00"
          >
            <Grid item xs={1} sx={{ pointerEvents: 'all' }}>
              <SidebarWithIcons
                items={sidebarItems}
                onChange={onChangeSidebar}
                selected={selectedSection}
                iconColor={theme.palette.mulberry.main}
              />
            </Grid>
          </SectionLayout>
        </Box>
      </Box>
    </>
  )
}

export default OfferPageSidebarTablet
