import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  StarOutlinedIcon,
  StarFilledIcon,
  HouseOutlinedIcon,
  HouseFilledIcon,
  DocumentsIcon,
  DocumentsFilledIcon,
  MapIcon,
  MapFilledIcon,
  CheckIcon,
  CheckFilledIcon,
} from '@flock/shared-ui'
import OfferDetails from './OfferDetails'
import OfferDocuments from './OfferDocuments/OfferDocuments'
import OfferPagePaper from './OfferPagePaper'
import OfferPageSection from './OfferPageSection'

import { OfferPageData } from './offerPageTypes'
import OfferTasks from './OfferTasks/OfferTasks'

import { scrollIntoView, useInView } from '../utils'

import OfferPageSidebar from './OfferPageSidebar/OfferPageSidebar'
import OfferExchange from './OfferExchange/OfferExchange'
import OfferPortfolio from './OfferPortfolio/OfferPortfolio'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

type OfferPageProps = {
  pageData: OfferPageData
  leadUuid: string
  showHomeDetails: boolean
}

const OfferPage = (props: OfferPageProps) => {
  const { pageData, leadUuid, showHomeDetails } = props

  const {
    zipcode,
    cityStateZipcode,
    hasCityStateZipcodeOverride,
    latitude,
    longitude,
    propertyCondition,
    scheduleInspectionRequested,
    contributionAgreementRequested,
    mortgage,
    hideValuation,
    finalOffer,
    prelimOffer,
    offerLow,
    offerHigh,
    expiryDate,
    updateScheduleInspectionRequested,
    updateContributionAgreementRequested,
    saamValuationType,
    leadStage,
    street,
    slackThreadUrl,
    leadStatusOverride,
    shouldOverrideLeadStatus,

    leadStatus,
    inspectionDate,
    leadDocuments,
    staticDocumentOverrides,

    bedrooms,
    bathrooms,
    halfBaths,
    sqft,
    rent,
    propertyTypeDisplayString,
    transactionType,
    isOverriddenAddress,
    fullName,
    portfolioName,
    portfolioDoorCount,
    portfolioAssetCount,
    portfolioCityState,
    portfolioSFRDoors,
    portfolioMultifamilyDoors,
    portfolioDrawerContent,
    cashTakeout,
    capexCosts,
    shareCount,
  } = pageData

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const offerPortfolioText = isMobile
    ? 'Offers are based on condition, rental comps, and fund requirements.'
    : 'The Overmoon 721 Fund offers are based on property condition, sale and rental comps, and fund performance requirements.'

  const [selectedSection, setSelectedSection] = useState(0)
  const [sidebarHeight, setSidebarHeight] = useState(0)
  const sidebarRef = useCallback((domElement: HTMLDivElement) => {
    if (domElement !== null) {
      setSidebarHeight(domElement.clientHeight)
    }
  }, [])

  const sectionIds = useMemo(
    () =>
      showHomeDetails
        ? ['offer', 'details', 'exchange', 'next-steps', 'documents']
        : ['offer', 'details', 'next-steps', 'documents'],
    [showHomeDetails]
  )

  const currentSection = useInView(sectionIds)

  const sidebarItems = [
    {
      title: 'Offer',
      unselectedIcon: StarOutlinedIcon,
      selectedIcon: StarFilledIcon,
      id: 'offer',
    },
    {
      title: 'Details',
      unselectedIcon: HouseOutlinedIcon,
      selectedIcon: HouseFilledIcon,
      id: 'details',
    },
    {
      title: 'Exchange',
      unselectedIcon: MapIcon,
      selectedIcon: MapFilledIcon,
      id: 'exchange',
      hide: !shareCount || shareCount < 0,
    },
    {
      title: 'Next steps',
      unselectedIcon: CheckIcon,
      selectedIcon: CheckFilledIcon,
      id: 'next-steps',
    },
    {
      title: 'Documents',
      unselectedIcon: DocumentsIcon,
      selectedIcon: DocumentsFilledIcon,
      id: 'documents',
    },
  ]

  const onChangeSidebar = (newTab: number) => {
    scrollIntoView(sectionIds[newTab])
  }

  useEffect(() => {
    setSelectedSection(sectionIds.indexOf(currentSection))
  }, [currentSection, sectionIds])

  // if the lead only provided a full name, remove the "Unknown" part
  const displayFullName = fullName?.replace(' Unknown', '')

  return (
    <>
      <OfferPageSidebar
        sidebarRef={sidebarRef}
        sidebarHeight={sidebarHeight}
        leadStatus={leadStatus}
        sidebarItems={sidebarItems}
        onChangeSidebar={onChangeSidebar}
        selectedSection={selectedSection}
      />
      <OfferPageSection
        name="offer-details"
        backgroundColor="gray1.main"
        id="offer"
      >
        {!isMobile && <Box pt="38px" />}
        <OfferDetails
          name={displayFullName}
          street={street}
          zipcode={zipcode}
          cityStateZipcode={cityStateZipcode}
          expiryDate={expiryDate}
          latitude={latitude}
          longitude={longitude}
          hideValuation={hideValuation}
          finalOffer={finalOffer}
          prelimOffer={prelimOffer}
          offerLow={offerLow}
          offerHigh={offerHigh}
          propertyCondition={propertyCondition}
          hasCityStateZipcodeOverride={hasCityStateZipcodeOverride}
          leadStage={leadStage}
          isOverriddenAddress={isOverriddenAddress}
          saamValuationType={saamValuationType}
          leadStatus={leadStatus}
          inspectionDate={inspectionDate}
          leadUuid={leadUuid}
          transactionType={transactionType}
          scheduleInspectionRequested={scheduleInspectionRequested}
          updateScheduleInspectionRequested={updateScheduleInspectionRequested}
          contributionAgreementRequested={contributionAgreementRequested}
          updateContributionAgreementRequested={
            updateContributionAgreementRequested
          }
        />
      </OfferPageSection>
      <OfferPageSection
        name="home-details"
        backgroundColor="gray1.main"
        id="details"
        shouldHide={isOverriddenAddress}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={isMobile ? '16px' : '24px'}
        >
          <Typography color="moneyGreen.main" variant="cta">
            {offerPortfolioText}
          </Typography>
          <OfferPagePaper>
            <OfferPortfolio
              street={street}
              cityStateZip={cityStateZipcode}
              bedrooms={bedrooms}
              bathrooms={(bathrooms || 0) + (halfBaths || 0) / 2}
              homeType={propertyTypeDisplayString}
              condition={propertyCondition}
              sqft={sqft || 0}
              rent={rent || 0}
              transactionType={transactionType}
              leadName={displayFullName}
              portfolioName={portfolioName}
              portfolioDoorCount={portfolioDoorCount}
              portfolioAssetCount={portfolioAssetCount}
              portfolioCityState={portfolioCityState}
              portfolioSFRDoors={portfolioSFRDoors}
              portfolioMultifamilyDoors={portfolioMultifamilyDoors}
              capex={capexCosts}
              offerPrice={finalOffer || prelimOffer}
            />
          </OfferPagePaper>
        </Box>
      </OfferPageSection>
      {showHomeDetails && (
        <OfferPageSection
          name="appreciation-potential"
          backgroundColor="gray1.main"
          id="exchange"
        >
          <Box
            pb={isMobile ? '16px' : '24px'}
            display="flex"
            gap="32px"
            flexDirection="column"
          >
            <Typography variant="cta" color="moneyGreen.main">
              You receive shares in the Overmoon 721 Fund&apos;s nationwide
              portfolio based on the value of the home you contribute.
            </Typography>
          </Box>
          <OfferPagePaper>
            <OfferExchange
              finalOffer={finalOffer}
              street={street}
              mortgage={mortgage || 0}
              transactionType={transactionType}
              rentReduction={portfolioDrawerContent.rentReduction || 0}
              cashTakeout={cashTakeout || 0}
              daysInRemodelDeduction={
                portfolioDrawerContent?.daysInRemodelDeduction || 0
              }
              onboardingFee={portfolioDrawerContent.onboardingFee}
              brokerCommission={portfolioDrawerContent?.brokerCommission || 0}
            />
          </OfferPagePaper>
        </OfferPageSection>
      )}
      <OfferPageSection
        name="offer-tasks"
        backgroundColor="gray1.main"
        id="next-steps"
      >
        <OfferTasks
          leadStatus={leadStatus}
          leadStatusOverride={leadStatusOverride}
          shouldOverrideLeadStatus={shouldOverrideLeadStatus}
          inspectionDate={inspectionDate}
          zipcode={zipcode}
          expiryDate={expiryDate}
          leadStage={leadStage}
          leadUuid={leadUuid}
          transactionType={transactionType}
        />
      </OfferPageSection>
      <OfferPageSection
        name="offer-documents"
        backgroundColor="gray1.main"
        id="documents"
      >
        <OfferDocuments
          leadDocuments={leadDocuments || []}
          staticDocumentOverrides={staticDocumentOverrides || {}}
          slackThreadUrl={slackThreadUrl}
        />
      </OfferPageSection>
    </>
  )
}

export default OfferPage
